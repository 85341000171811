import * as React from 'react';
import { generateCorrelation } from './generateCorrelation';
import { isChunkLoadingError } from './isChunkLoadingError';

export function ErrorFallback({ error }: { error: Error }) {
  const correlation = generateCorrelation(error);

  // show a slightly different message for chunk loading errors
  if (isChunkLoadingError(error)) {
    // now, even though this is in the render loop, we _should_ be ok, as this component should only be rendered once
    // We are being completely swamped by these errors in rollbar so we are stopping them for the moment
    // debugChunkLoadingError(error);

    return (
      <div className="react-error-fallback">
        <h2 className="bp3-heading">Unable to load</h2>
        <div className="bp3-text-muted bp3-caption">
          This component could not load correctly - this may be caused by an ad blocker, slow network connection, or
          corporate firewall.
          <br />
          Please disable your ad blocker on this site, or try a different browser or device. If the problem persists,
          please contact support. {correlation && <span style={{ opacity: 0.3 }}>Reference: {correlation}</span>}
        </div>
      </div>
    );
  }

  return (
    <div className="react-error-fallback">
      <h2 className="bp3-heading">Sorry, looks like something went wrong</h2>
      <div className="bp3-text-muted bp3-caption">
        If the problem persists, please contact support.{' '}
        {correlation && <span style={{ opacity: 0.3 }}>Reference: {correlation}</span>}
      </div>
    </div>
  );
}
