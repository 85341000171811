import * as Rollbar from 'rollbar';

/**
 * Wrapper around an external error reporting tool - such as Rollbar
 * { response: Response; originalError: any } is what we get from Spraypaint
 */
export function reportError(error: Error | string | { response: Response; originalError: any }, additionalData?: any) {
  const rollbar = (window as any).Rollbar as Rollbar;
  if (rollbar !== undefined) rollbar.error(error, additionalData);
}

/**
 * Describes an error that appears nicely to the user
 */
export function reportHandledError(error: Error | string, additionalData?: any) {
  const rollbar = (window as any).Rollbar as Rollbar;
  if (rollbar !== undefined) rollbar.error(error, { ...additionalData, handled: true });
}

/**
 * Wrapper around an external error reporting tool - such as Rollbar
 */
export function reportInfo(error: Error | string, additionalData?: any) {
  const rollbar = (window as any).Rollbar as Rollbar;
  if (rollbar !== undefined) rollbar.info(error, { ...additionalData });
}
