// Javascript to open the hamburger menu

import * as $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
  $('.js-hamburger-menu').on('click', function () {
    if ($(this).hasClass('mobile-menu--active')) {
      $(this).removeClass('mobile-menu--active');
    } else {
      $(this).addClass('mobile-menu--active');
    }
    const target = $(this).data('target');
    $(`.${target}`).slideToggle(200);
  });
});
