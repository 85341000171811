import * as $ from 'jquery';

$(document).on('change', '.js-interview-date-radio', function() {
  const date = $(this);
  const times = $('#' + date.val());
  $('.js-interview-times').addClass('hidden');
  times.removeClass('hidden');
  // const radioGroup = times.find('.js-interview-time-radio');
  // let firstRadio;
  // if (!radioGroup.is(':checked')) {
  //   firstRadio = radioGroup.first();
  // }
  // firstRadio.prop('checked', true);
  // firstRadio.trigger('change');
});

$(document).on('change', '.js-interview-time-radio', function() {
  const data = $(this).data();
  $(data['confirmId']).find('.js-interview-text').text(data['text']);
});
