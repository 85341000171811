import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CompanySettingProvider, ICompanySettings } from '../../javascript/application/useCompanySettings';
import { FeatureFlagProvider, FeatureFlags } from '../../javascript/application/useFeatureFlags';
import { OnboardingSettings, OnboardingSettingsProvider } from '../../javascript/application/useOnboardingSettings';
import { IUserRoles, UserRolesProvider } from '../../javascript/application/useUserRoles';
import { LocaleContextProvider, TEnabledLocaleKeys } from './hooks/useLocaleContext';

export function GlobalProviderWrapper({
  companySettings,
  enabledLocaleKeys,
  featureFlags,
  onboardingSettings,
  userRoles,
  children,
}: {
  companySettings: ICompanySettings;
  enabledLocaleKeys: TEnabledLocaleKeys;
  featureFlags: FeatureFlags;
  onboardingSettings: OnboardingSettings;
  userRoles: IUserRoles;
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <LocaleContextProvider enabledLocaleKeys={enabledLocaleKeys}>
        <FeatureFlagProvider featureFlags={featureFlags}>
          <CompanySettingProvider companySettings={companySettings}>
            <OnboardingSettingsProvider {...{ onboardingSettings }}>
              <UserRolesProvider userRoles={userRoles}>
                {/* Actual component being rendered */}
                {children}
              </UserRolesProvider>
            </OnboardingSettingsProvider>
          </CompanySettingProvider>
        </FeatureFlagProvider>
      </LocaleContextProvider>
    </QueryClientProvider>
  );
}
