import * as React from 'react';

/**
 * The shape of our OnboardingSettings object, narrowed down to the available keys and values only.
 */
export type OnboardingSettings = {
  isOnboardingEnabled: boolean;
};

/**
 * Initial value for all onboarding settings in the application.
 * Making these hardcoded to 'unset' as they should not impact the application at
 * run time one way or the other.
 */
const onboardingSettingsInitialValues: OnboardingSettings = {
  isOnboardingEnabled: false,
};

export const OnboardingSettingsContext = React.createContext<OnboardingSettings>(onboardingSettingsInitialValues);

export const useOnboardingSettings = () => {
  const onboardingSettings = React.useContext(OnboardingSettingsContext);

  return React.useMemo(
    () => ({
      isOnboardingEnabled: onboardingSettings.isOnboardingEnabled,
    }),
    [onboardingSettings],
  );
};

interface IOnboardingSettingsProviderProps {
  onboardingSettings: OnboardingSettings;
}

export function OnboardingSettingsProvider({
  onboardingSettings,
  children,
}: React.PropsWithChildren<IOnboardingSettingsProviderProps>) {
  return (
    <OnboardingSettingsContext.Provider
      value={{
        ...onboardingSettingsInitialValues,
        ...onboardingSettings,
      }}
    >
      {children}
    </OnboardingSettingsContext.Provider>
  );
}
