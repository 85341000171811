import * as $ from 'jquery';

// Makes CSS animations play nice with Turbolinks

// If we dont use JS to add / remove the CSS animation class, the turbolinks cache will cause a
// 'double' exposure of sorts

document.addEventListener('turbolinks:load', () => {
  $('.js-animated').addClass('animate__animated');
});

document.addEventListener('turbolinks:before-visit', () => {
  $('.animated').removeClass('animate__animated').css('opacity', '0');
});
