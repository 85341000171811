/**
 * Automatically Generated with Rails using {@link config/custom/feature_flags.yml}
 * see {@link lib/generators/feature_flags/feature_flags_generator.rb} for details
 * run `rails g feature_flags -f` to regenerate
 */

import * as React from 'react';

/**
 * All the possible feature flags that can be used within the application
 */
type FeatureFlagKeys =
  | 'job_templates';

/**
 * The valid states for a given feature flag
 */
type FeatureFlagStatus = 'disabled' | 'enabled' | 'unset';

/**
 * The shape of our FeatureFlag object, narrowed down to the available keys and values only.
 */
export type FeatureFlags = Record<FeatureFlagKeys, FeatureFlagStatus>;

/**
 * Initial value for all feature flags in the application.
 * Making these hardcoded to 'unset' as they should not impact the application at
 * run time one way or the other.
 */
const featureFlagsInitialValues: FeatureFlags = {
  job_templates: 'unset',
};

export const FeatureFlagsContext = React.createContext<FeatureFlags>(featureFlagsInitialValues);

export const useFeatureFlags = () => {
  const featureFlags = React.useContext(FeatureFlagsContext);

  return React.useMemo(
    () => ({
      isJobTemplatesEnabled: featureFlags.job_templates === 'enabled',
    }),
    [featureFlags],
  );
};

interface IFeatureFlagProviderProps {
  featureFlags: FeatureFlags;
}

export function FeatureFlagProvider({ featureFlags, children }: React.PropsWithChildren<IFeatureFlagProviderProps>) {
  return (
    <FeatureFlagsContext.Provider
      value={{
        ...featureFlagsInitialValues,
        ...featureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}
