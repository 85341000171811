import * as $ from 'jquery';

// Add error message to the page at the specified form field location

// The data-error-message attribute we're using is added by overriding the field_with_errors (see config/initializers)

document.addEventListener('turbolinks:load', () => {
  const fields = [];
  $('[data-error-message]').each(function() {
    // For radio / checkboxes we only want to display the error once
    if (fields.includes($(this).attr('name'))) {
      return;
    }

    let wrapper = $(this);
    if (
      $(this)
        .parent()
        .is('.checkable-input')
    ) {
      wrapper = $(this).closest('.frow');
    }

    $(wrapper).after(
      `<p class="text-brand-brick-red" style="font-size: 1.4rem; margin: 0.5em 0 1em;">${$(this).data(
        'error-message',
      )}</p>`,
    );

    fields.push($(this).attr('name'));
  });
});
