/* eslint-disable import/newline-after-import */
/* eslint-disable import/first */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// polyfills
import 'core-js';

/* --------------------------------------------------
  Turbolinks
-------------------------------------------------- */
import * as Turbolinks from 'turbolinks';
Turbolinks.start();

/* --------------------------------------------------
  Rails UJS (replaces jquery-ujs)
-------------------------------------------------- */
import * as Rails from '@rails/ujs';
Rails.start();

/* --------------------------------------------------
  ActiveStorage
-------------------------------------------------- */
import * as ActiveStorage from '../vendor/activestorage';
ActiveStorage.start();

/* --------------------------------------------------
  React Components
-------------------------------------------------- */
import { components } from '../components/pinpointComponents';
import { registerReactComponents } from '../components/registerReactComponents';
registerReactComponents(components);

/* --------------------------------------------------
  Custom Javascript
-------------------------------------------------- */
import '../javascript';

/* --------------------------------------------------
  Styles
-------------------------------------------------- */
import '../stylesheets/application.scss';

/* --------------------------------------------------
  Images - so we're adding them to the pack manifest
-------------------------------------------------- */
require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);

/* --------------------------------------------------
  Trix
  Setting custom configuration
-------------------------------------------------- */
import trix from 'trix';

trix.config.attachments.preview.caption = {
  name: false,
  size: false,
};

trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

trix.config.blockAttributes.heading3 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

/* --------------------------------------------------
  // SmoothScroll
  // https://www.npmjs.com/package/smooth-scroll
-------------------------------------------------- */
import * as SmoothScroll from 'smooth-scroll';

const scroll = new SmoothScroll('a[href^="#"]', {
  clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
  durationMax: 800, // Integer. The maximum amount of time the scroll animation should take
  durationMin: 200, // Integer. The minimum amount of time the scroll animation should take
  easing: 'easeInOutCubic', // Easing pattern to use
  emitEvents: false, // Emit custom events
  header: null, // Selector for fixed headers (must be a valid CSS selector)
  ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid CSS selector)
  offset: 130, // Integer or Function returning an integer.
  popstate: true, // Animate scrolling with the forward/backward browser buttons (requires updateURL to be true)
  speed: 1500, // Integer. Amount of time in milliseconds it should take to scroll 1000px
  speedAsDuration: false, // If true, use speed as the total duration of the scroll animation
  topOnEmptyHash: true, // Scroll to the top of the page for links with href="#"
  updateURL: true, // Update the URL on scroll
});

/* --------------------------------------------------
  // AirBnB React Dates
  // https://github.com/airbnb/react-dates
-------------------------------------------------- */
import 'react-dates/initialize';

/* --------------------------------------------------
  // Isomorphic Fetch - to ensure that fetch works in IE11
  // https://github.com/matthew-andrews/isomorphic-fetch
-------------------------------------------------- */
import 'isomorphic-fetch';

/* --------------------------------------------------
  Jquery
  NOTE: (TomLuce) I've only added this in so that our legacy coffeescript components can function without being
  altered. We've had to include jquery_ujs to those components to automagically place the csrf token, and it
  needs JQuery to be defined globally in order to function
-------------------------------------------------- */
import * as $ from 'jquery';
(global as any).jQuery = $;
