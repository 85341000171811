import { scrollToId } from '../../components/shared/utilities/scrollTo';

// Scrolls to an ID if there's a value of 'scroll_to' in the params, then removes that param from the URL
//
// Why not just stick and anchor as part of the URL you cry?
//
// If you've got JS that loads content in above the element you're trying to scroll to it'll scroll
// on page load, but then move when the JS loads in its content. There's no real way to know when this
// content has finished loading short of listening for callbacks on the elements themselves.
//
// I dont want to listen for callbacks because:
// a) not all libraries present callbacks
// b) we don't know what Javascript to care about (i.e which bits are above our element in the page flow)
// c) it's really brittle - we might change our JS implementation and this won't work
//
// This leaves us with setting a delay before scrolling in the hope that enough
// of the content would have loaded before it attempts to scroll to the right place.
//
document.addEventListener('turbolinks:load', () => {
  const params = new URLSearchParams(window.location.search);
  const scrollTo = params.get('scroll_to');
  if (scrollTo) {
    window.scroll(0, 0);
    params.delete('scroll_to');
    const url = params.toString().length ? `${location.pathname}?${params}` : location.pathname;
    window.history.replaceState({}, '', url);
    setTimeout(() => scrollToId(scrollTo), 600);
  }
});
