import * as React from 'react';
import type { TRequiredLocaleKey } from '../utilities/locales';
import { useLocale } from './useLocale';

export type TEnabledLocaleKeys = TRequiredLocaleKey[];

interface ILocaleContext {
  editingLocale: TRequiredLocaleKey;
  setEditingLocale: (locale: TRequiredLocaleKey) => void;
  enabledLocaleKeys: TRequiredLocaleKey[];
}

const LocaleContext = React.createContext<ILocaleContext>({
  editingLocale: 'en',
  enabledLocaleKeys: ['en'],
  setEditingLocale: () => {
    // do nothing
  },
});

export function useLocaleContext(): ILocaleContext {
  return React.useContext(LocaleContext);
}

export function LocaleContextProvider({
  enabledLocaleKeys = ['en'],
  children,
}: React.PropsWithChildren<{
  enabledLocaleKeys: TRequiredLocaleKey[];
}>) {
  const [editingLocale, setEditingLocale] = useLocale(enabledLocaleKeys[0]);

  const value = React.useMemo(
    () => ({ editingLocale, setEditingLocale, enabledLocaleKeys }),
    [editingLocale, setEditingLocale, enabledLocaleKeys],
  );

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}
