import { Classes } from '@pinpointhq/thumbtack';
import { delay } from 'lodash';
import * as SmoothScroll from 'smooth-scroll';

export function scrollToElement(element: Document | Element, offset = 50) {
  const scrollingClass = 'smooth-scrolling';
  const durationMax = 500;
  document.body.classList.add(scrollingClass);
  delay(() => {
    document.body.classList.remove(scrollingClass);
  }, durationMax);
  const scroll = new SmoothScroll();
  const options = {
    clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
    durationMax, // Integer. The maximum amount of time the scroll animation should take
    durationMin: 200, // Integer. The minimum amount of time the scroll animation should take
    easing: 'easeInOutCubic', // Easing pattern to use
    emitEvents: false, // Emit custom events
    offset, // Integer or Function returning an integer.
    popstate: false, // Animate scrolling with the forward/backward browser buttons (requires updateURL to be true)
    speed: 500, // Integer. Amount of time in milliseconds it should take to scroll 1000px
    speedAsDuration: false, // If true, use speed as the total duration of the scroll animation
    updateURL: true, // Update the URL on scroll
  };
  scroll.animateScroll(element, null, options);
}

export function scrollToId(id: string, offset = 50) {
  return scrollToElement(document.getElementById(id), offset);
}

// NOTE: If we want to not tie things to Thumbtack, use the below line instead.
// const formFeedbackTextClass = 'bp3-form-feedback-text';
const formFeedbackTextClass = Classes.FORM_FEEDBACK_TEXT;

export function scrollToFirstError() {
  const formFeedbackElements = document.getElementsByClassName(formFeedbackTextClass);
  if (formFeedbackElements.length) scrollToElement(formFeedbackElements[0], 300);
}
