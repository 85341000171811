import { start } from './ujs';
import { DirectUpload } from './direct_upload';
export { start, DirectUpload };

function autostart() {
  if (window.ActiveStorage) {
    start();
  }
}

setTimeout(autostart, 1);

// https://github.com/rails/rails/blob/6-1-stable/activestorage/app/javascript/activestorage/index.js
/* ActiveStorage Pinpoint Modification Before ----------
// ActiveStorage Pinpoint Modification After ----------- */
export const Blob = {}; // this is to prevent a TS error in 3.6 because we can't just export a type
// ActiveStorage Pinpoint Modification End -------------
