/**
 * What is this and why is it needed?
 *
 * So in order to execute script tags in line with our content security policy we add a +nonce+ attribute to them.
 * The problem is that Chrome strips this nonce attribute from the script tags in the DOM - this messes with Turbolinks.
 * @see https://stackoverflow.com/questions/55670985/google-chrome-stripping-nonce-values-from-script-tags
 * It is worth noting that Firefox does not strip the nonces. I am not sure about other browsers.
 *
 * Turbolinks caches the DOM before fetching a new page, and in this saved state the DOM does not have nonces on
 * script tags. It receives a server response (which does have nonces on it),
 * then does a diff and reasons that it needs to add/append the script tag, which then re-executes the script.
 * This re-running causes havoc with things like Posthog which throws a maximum call stack size exceeded error
 *
 * The fix is to add the nonces in to the script tags before Turbolinks caches anything. That way it's diff with the
 * fetched content is the same and it does not re-execute anything.
 *
 * We're fortunate that Rails put the nonce value in a meta tag. I believe its put there so that things like
 * Rails UJS can grab it when making requests.
 *
 * There is more info here about turbolinks and CSP. Point 3 states:
 * => "Because nonces can only be accessed via their IDL attribute after the page loads (for security reasons),
 * they need to be read via JS and added back as normal attributes in the DOM before the page is cached
 * otherwise on cache restoration visits, the nonces won’t be there!"
 * @see https://discuss.rubyonrails.org/t/turbolinks-broken-by-default-with-a-secure-csp/74790
/ */
document.addEventListener('turbolinks:before-cache', () => {
  const nonceElement = document.head.querySelector(`meta[name="csp-nonce"]`);
  /**
   * We always expect the nonce value to be set in the meta tag, but if for some reason its not I
   * want to make sure this does not blow up
   */
  let nonceValue = '';
  if (nonceElement) {
    nonceValue = nonceElement.getAttribute('content');
  }

  /**
   * We only want to make sure we target those script tags that have an empty nonce defined, otherwise we
   * end up just applying the nonce attribute to some script tags that won't get a nonce attribute added
   * when we change page
   */
  const htmlElements = document.querySelectorAll('script[nonce]');
  for (let i = 0; i < htmlElements.length; i++) {
    const scriptTag = htmlElements[i];
    scriptTag.setAttribute('nonce', nonceValue);
  }
});
