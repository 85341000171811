import { FileChecksum } from './file_checksum';
import { BlobRecord } from './blob_record';
import { BlobUpload } from './blob_upload';

let id = 0;

export class DirectUpload {
  constructor(file, url, delegate) {
    this.id = ++id;
    this.file = file;
    this.url = url;
    this.delegate = delegate;
  }

  create(callback) {
    FileChecksum.create(this.file, (error, checksum) => {
      if (error) {
        /* ActiveStorage Pinpoint Modification Before ----------
        callback(error);
        // ActiveStorage Pinpoint Modification After ----------- */
        callback(error, checksum);
        // ActiveStorage Pinpoint Modification End -------------
        return;
      }

      const blob = new BlobRecord(this.file, checksum, this.url);
      notify(this.delegate, 'directUploadWillCreateBlobWithXHR', blob.xhr);

      /* ActiveStorage Pinpoint Modification Before ----------
      blob.create((error) => {
        if (error) {
          callback(error);
        } else {
          const upload = new BlobUpload(blob);
          notify(this.delegate, 'directUploadWillStoreFileWithXHR', upload.xhr);
          upload.create((error) => {
            if (error) {
              callback(error);
            } else {
              callback(null, blob.toJSON());
            }
          });
        }
      });
      // ActiveStorage Pinpoint Modification After ----------- */
      blob.create((error, additionalErrorData) => {
        if (error) {
          callback(error, additionalErrorData);
        } else {
          const upload = new BlobUpload(blob);
          notify(this.delegate, 'directUploadWillStoreFileWithXHR', upload.xhr);
          upload.create((error, additionalErrorData) => {
            if (error) {
              callback(error, additionalErrorData);
            } else {
              callback(null, blob.toJSON());
            }
          });
        }
      });
      // ActiveStorage Pinpoint Modification End -------------
    });
  }
}

function notify(object, methodName, ...messages) {
  if (object && typeof object[methodName] == 'function') {
    return object[methodName](...messages);
  }
}
