import { useState } from 'react';
import type { TRequiredLocaleKey } from '../utilities/locales';

export function useLocale(
  initialLocale: TRequiredLocaleKey = 'en',
): [TRequiredLocaleKey, (locale: TRequiredLocaleKey) => void] {
  const [editingLocale, setEditingLocale] = useState<TRequiredLocaleKey>(initialLocale);
  return [editingLocale, setEditingLocale];
}

export default useLocale;
