import { mapValues } from 'lodash';
import * as React from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactOnRails from 'react-on-rails';
import type { RenderFunction } from 'react-on-rails/node_package/lib/types';
import { ICompanySettings } from '../javascript/application/useCompanySettings';
import { FeatureFlags } from '../javascript/application/useFeatureFlags';
import { OnboardingSettings } from '../javascript/application/useOnboardingSettings';
import { IUserRoles } from '../javascript/application/useUserRoles';
import { ErrorFallback } from './ErrorFallback';
import { generateCorrelation } from './generateCorrelation';
import { isChunkLoadingError } from './isChunkLoadingError';
import { GlobalProviderWrapper } from './shared/GlobalProviderWrapper';
import { TEnabledLocaleKeys } from './shared/hooks/useLocaleContext';
import { reportError } from './shared/utilities/reportError';

function SuspenseFallback() {
  return <div className="react-suspense-fallback" />;
}

function reportReactComponentError(error: Error) {
  // We aren't reporting chunk load errors to rollbar to reduce the noise we are seeing in there
  //
  if (isChunkLoadingError(error)) return null;

  const correlation = generateCorrelation(error);
  reportError(error, { errorFallbackRendered: true, correlation });

  return null;
}

function wrapComponentInRenderFunction(LoadedComponent: React.ElementType<any>) {
  const renderFunction: RenderFunction = (props, railsContext) => {
    const { companySettings, enabledLocaleKeys, featureFlags, onboardingSettings, userRoles, ...rest } = props;
    // https://www.shakacode.com/react-on-rails/docs/guides/render-functions-and-railscontext/
    const noArgumentsRenderFunction = () => {
      return (
        <GlobalProviderWrapper
          companySettings={companySettings as ICompanySettings}
          enabledLocaleKeys={enabledLocaleKeys as TEnabledLocaleKeys}
          featureFlags={featureFlags as FeatureFlags}
          onboardingSettings={onboardingSettings as OnboardingSettings}
          userRoles={userRoles as IUserRoles}
        >
          <Suspense fallback={<SuspenseFallback />}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportReactComponentError}>
              <LoadedComponent
                {...rest}
                // TODO: TOTIDY remove the below once we know that no sub-contexts are using them also
                // eg. when we have removed all the providers throughout the components
                companySettings={companySettings}
                enabledLocaleKeys={enabledLocaleKeys}
                featureFlags={featureFlags}
              />
            </ErrorBoundary>
          </Suspense>
        </GlobalProviderWrapper>
      );
    };
    return noArgumentsRenderFunction;
  };
  return renderFunction;
}

interface IRegisterReactComponents {
  [id: string]: React.ElementType<any>;
}

export function registerReactComponents(components: IRegisterReactComponents) {
  const wrappedComponents = mapValues(components, (component) => wrapComponentInRenderFunction(component));
  ReactOnRails.register(wrappedComponents);
}
